@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

html,
body,
#root,
.scroll {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  transition: opacity 800ms ease-in-out, transform 800ms ease-in-out;
}

.scroll > div {
  /* scroll-snap-align: start; */
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background-color: #101010;
  background: radial-gradient(circle at bottom center, #212121 0%, #101010 80%);
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 1000ms ease-in-out;
}

.caption {
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  margin: 80px;
  color: white;
  font-size: 2em;
  font-weight: 100;
  line-height: 1em;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: auto;
}

.dot {
  /* pointer-events: none; */
  position: sticky;
  top: 0px;
  display: inline-block;
  /* max-width: 600px; */
  /* padding: 0;
  padding: 80px; */
  color: #a0a0a0;
  line-height: 1.6em;
  font-size: 15px;
  letter-spacing: 1.5px;
}

/* .dot > h1 {
  -webkit-font-smoothing: auto;
  pointer-events: none;
  color: white;
  font-size: 5em;
  font-weight: 100;
  line-height: 1em;
  margin: 0;
  margin-bottom: 0.25em;
} */

@media only screen and (max-width: 1000px) {
  .caption {
    font-size: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .caption {
    font-size: 3em;
  }
  /* .dot > h1 {
    font-size: 3em;
  } */
}

@media only screen and (max-width: 700px) {
  .caption {
    font-size: 2em;
  }
  /* .dot > h1 {
    font-size: 3em;
  } */
}

@media only screen and (max-width: 600px) {
  .caption {
    font-size: 1em;
  }
  /* .dot > h1 {
    font-size: 3em;
  } */
}

/* Class for texts to make them filled based on the background (inverted/difference dont know the term) */

.text-fill {
  -webkit-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;

  -webkit-background-clip: text;
  background-clip: text;
}

.blend-me {
  mix-blend-mode: exclusion;
}
